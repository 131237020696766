body {
  background: $gray-200;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23868588' fill-opacity='0.4' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
}

.wrapper {
  background: $white;
  box-shadow: 0 0 0.8rem rgb(128 128 128 / 30%);
}

// Header

.site__header {
  background-color: #fff;
  color: $gray-600;
  padding: 1rem;
}

.site__name {
  display: block;
  overflow: hidden;
  margin: 0;

  a {
    display: block;
    text-indent: -1000%;
    background: transparent url(../img/moka.svg) 50% 50% no-repeat;
    background-size: contain;
    height: 4rem;
    width: 10rem;

    &:hover {
      text-decoration: none;
    }
  }
}

// Navigation
.navbar {
  padding: 0;
}

.navbar-nav {
  font-size: 1.4rem;

  @include respond-above(sm) {
    flex-direction: row;

    li {
      margin-right: 1rem;
      word-break: keep-all;
      hyphens: none;

      &.active .nav-link {
        font-weight: 600;
      }
    }
  }
}

.navbar-toggler {
  color: $sonora-rose;

  // transform: rotate(2.5deg);
}

// Styles for content
main p a {
  text-decoration: underline;
  hyphens: none;
}

img,
figure {
  @include img-fluid;
}

// icons
.icon {
  display: inline-block;
  vertical-align: middle;
  width: 1rem;
  height: 1rem;

  a & {
    fill: $link-color;
  }

  .disabled a & {
    fill: $custom-select-disabled-color;
  }
}

.rich-text {
  ul {
    @extend .list-unstyled;

    padding: 0;
    margin: 0;

    li {
      padding-left: 0.4rem;

      &::before {
        content: "•";
        padding-right: 0.4rem;
        color: $link-color;
      }
    }
  }

  // blocks
  p {
    text-align: justify;
  }
}

figure {
  margin: 0;

  figcaption {
    @extend .sr-only;

    text-align: center;
    margin: 0.3rem auto;
    font-size: 90%;
    color: var(--dark);
    background-color: inherit;
  }
}

// Bootstrap carousel
.carousel-control-prev-icon,
.carousel-control-next-icon {
  filter: drop-shadow(1px 1px 1px rgb(128 128 128 / 70%));
}

// Blocks
.block {
  // Spalte
  &--column {
    @include respond-above(sm) {
      &:nth-child(odd) {
        .block-image_block {
          margin-left: -$spacer;

          // 2. Bild verschoben nach rechts
          ul li:nth-child(2) figure {
            justify-content: flex-end;
            transform: translateX(5%);
          }
        }
      }

      &:nth-child(even) {
        .block-image_block {
          margin-right: -$spacer;
          grid-column-start: 1;

          // 2. Bild verschoben nach links
          ul li:nth-child(2) figure {
            left: 0;
            transform: translateX(-5%);
          }
        }
      }
    }

    // Bildergalerie
    &:last-child {
      grid-column: -1/1;
    }
  }

  &-image_block {
    position: relative;

    ul {
      @extend .list-unstyled;

      li {
        display: flex;
        flex-direction: column;
        justify-content: center;

        figure {
          display: flex;

          img {
            width: 100%;
            min-width: 100%;
            height: 100%;
          }
        }
      }

      li:nth-child(2) {
        figure {
          position: absolute;
          z-index: 2;

          // transform: translateY(-25%);
          top: 25%;

          img {
            min-width: 50%;
            width: 50%;
            box-shadow: 0.2rem 0.2rem 0.4rem rgb(128 128 128 / 60%);
            border: 0.3rem solid #fff;
          }
        }
      }
    }
  }

  &-image_gallery_block ul {
    @extend .grid;
    @extend .list-unstyled;

    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));

    img {
      min-width: 100%;
    }
  }
}

// Footer
.site__footer {
  background-color: $primary;
  color: $white;

  a {
    color: $white;
  }
}

.top {
  margin-right: 0.5rem;

  // dunkler footer
  svg {
    fill: #fff;
  }
}

// Animate.css
// https://github.com/daneden/animate.css
.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

@charset "UTF-8";
/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --primary: #312a31;
  --red: #8f1727;
}

.edge--bottom {
  position: relative;
  background-size: cover;
  z-index: 1;
}
.edge--bottom:after {
  background: inherit;
  content: "";
  display: block;
  height: 50%;
  left: 0;
  position: absolute;
  right: 0;
  z-index: -1;
}
.edge--bottom:after {
  bottom: 0;
  transform: skewY(-1.5deg);
  transform-origin: 100%;
}

.edge--bottom--reverse {
  position: relative;
  background-size: cover;
  z-index: 1;
}
.edge--bottom--reverse:after {
  background: inherit;
  content: "";
  display: block;
  height: 50%;
  left: 0;
  position: absolute;
  right: 0;
  z-index: -1;
}
.edge--bottom--reverse:after {
  bottom: 0;
  transform: skewY(1.5deg);
  transform-origin: 0 100%;
}

.edge--top {
  position: relative;
  background-size: cover;
  z-index: 1;
}
.edge--top:before {
  background: inherit;
  content: "";
  display: block;
  height: 50%;
  left: 0;
  position: absolute;
  right: 0;
  z-index: -1;
}
.edge--top:before {
  top: 0;
  transform: skewY(1.5deg);
  transform-origin: 100% 0;
}

.edge--top--reverse {
  position: relative;
  background-size: cover;
  z-index: 1;
}
.edge--top--reverse:before {
  background: inherit;
  content: "";
  display: block;
  height: 50%;
  left: 0;
  position: absolute;
  right: 0;
  z-index: -1;
}
.edge--top--reverse:before {
  top: 0;
  transform: skewY(-1.5deg);
  transform-origin: 0 0;
}

.edge--both {
  position: relative;
  background-size: cover;
  z-index: 1;
}
.edge--both:before, .edge--both:after {
  background: inherit;
  content: "";
  display: block;
  height: 50%;
  left: 0;
  position: absolute;
  right: 0;
  z-index: -1;
}
.edge--both::before {
  top: 0;
  transform: skewY(1.5deg);
  transform-origin: 100% 0;
}
.edge--both::after {
  bottom: 0;
  transform: skewY(-1.5deg);
  transform-origin: 100%;
}

.edge--both--reverse {
  position: relative;
  background-size: cover;
  z-index: 1;
}
.edge--both--reverse:before, .edge--both--reverse:after {
  background: inherit;
  content: "";
  display: block;
  height: 50%;
  left: 0;
  position: absolute;
  right: 0;
  z-index: -1;
}
.edge--both--reverse::before {
  top: 0;
  transform: skewY(-1.5deg);
  transform-origin: 0 0;
}
.edge--both--reverse::after {
  bottom: 0;
  transform: skewY(1.5deg);
  transform-origin: 0 0;
}

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #8f1727;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #312a31;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #8f1727;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #8f1727;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #63101b;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 1rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 1rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #312a31;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #312a31;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
}
.navbar > .container-xl, .navbar > .container-lg, .navbar > .container-md, .navbar > .container-sm, .navbar .container,
.navbar .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 2rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container-xl, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container-xl, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container-xl, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-md, .navbar-expand-md > .container-sm, .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container-xl, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-md, .navbar-expand-md > .container-sm, .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container-xl, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container-xl, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container-xl, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container-xl, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container-xl, .navbar-expand > .container-lg, .navbar-expand > .container-md, .navbar-expand > .container-sm, .navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container-xl, .navbar-expand > .container-lg, .navbar-expand > .container-md, .navbar-expand > .container-sm, .navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: transparent;
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #312a31 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #161216 !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #8f1727 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #63101b !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #312a31 !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #8f1727 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only, figure figcaption {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.5rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.5rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.5rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.5rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.5rem !important;
}

.m-2 {
  margin: 1rem !important;
}

.mt-2,
.my-2 {
  margin-top: 1rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 1rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 1rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 1rem !important;
}

.m-3 {
  margin: 2rem !important;
}

.mt-3,
.my-3 {
  margin-top: 2rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 2rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 2rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 2rem !important;
}

.m-4 {
  margin: 3rem !important;
}

.mt-4,
.my-4 {
  margin-top: 3rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 3rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 3rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 3rem !important;
}

.m-5 {
  margin: 6rem !important;
}

.mt-5,
.my-5 {
  margin-top: 6rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 6rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 6rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 6rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.5rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.5rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.5rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.5rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.5rem !important;
}

.p-2 {
  padding: 1rem !important;
}

.pt-2,
.py-2 {
  padding-top: 1rem !important;
}

.pr-2,
.px-2 {
  padding-right: 1rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 1rem !important;
}

.pl-2,
.px-2 {
  padding-left: 1rem !important;
}

.p-3 {
  padding: 2rem !important;
}

.pt-3,
.py-3 {
  padding-top: 2rem !important;
}

.pr-3,
.px-3 {
  padding-right: 2rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 2rem !important;
}

.pl-3,
.px-3 {
  padding-left: 2rem !important;
}

.p-4 {
  padding: 3rem !important;
}

.pt-4,
.py-4 {
  padding-top: 3rem !important;
}

.pr-4,
.px-4 {
  padding-right: 3rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 3rem !important;
}

.pl-4,
.px-4 {
  padding-left: 3rem !important;
}

.p-5 {
  padding: 6rem !important;
}

.pt-5,
.py-5 {
  padding-top: 6rem !important;
}

.pr-5,
.px-5 {
  padding-right: 6rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 6rem !important;
}

.pl-5,
.px-5 {
  padding-left: 6rem !important;
}

.m-n1 {
  margin: -0.5rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.5rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.5rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.5rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.5rem !important;
}

.m-n2 {
  margin: -1rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -1rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -1rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -1rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -1rem !important;
}

.m-n3 {
  margin: -2rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -2rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -2rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -2rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -2rem !important;
}

.m-n4 {
  margin: -3rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -3rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -3rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -3rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -3rem !important;
}

.m-n5 {
  margin: -6rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -6rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -6rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -6rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -6rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.5rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.5rem !important;
  }
  .m-sm-2 {
    margin: 1rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 1rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 1rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 1rem !important;
  }
  .m-sm-3 {
    margin: 2rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 2rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 2rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 2rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 2rem !important;
  }
  .m-sm-4 {
    margin: 3rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 3rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 3rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 3rem !important;
  }
  .m-sm-5 {
    margin: 6rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 6rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 6rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 6rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 6rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.5rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.5rem !important;
  }
  .p-sm-2 {
    padding: 1rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 1rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 1rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 1rem !important;
  }
  .p-sm-3 {
    padding: 2rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 2rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 2rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 2rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 2rem !important;
  }
  .p-sm-4 {
    padding: 3rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 3rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 3rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 3rem !important;
  }
  .p-sm-5 {
    padding: 6rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 6rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 6rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 6rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 6rem !important;
  }
  .m-sm-n1 {
    margin: -0.5rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n2 {
    margin: -1rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -1rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -1rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -1rem !important;
  }
  .m-sm-n3 {
    margin: -2rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -2rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -2rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -2rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -2rem !important;
  }
  .m-sm-n4 {
    margin: -3rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -3rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -3rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -3rem !important;
  }
  .m-sm-n5 {
    margin: -6rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -6rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -6rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -6rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -6rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.5rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.5rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.5rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.5rem !important;
  }
  .m-md-2 {
    margin: 1rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 1rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 1rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 1rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 1rem !important;
  }
  .m-md-3 {
    margin: 2rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 2rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 2rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 2rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 2rem !important;
  }
  .m-md-4 {
    margin: 3rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 3rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 3rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 3rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 3rem !important;
  }
  .m-md-5 {
    margin: 6rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 6rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 6rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 6rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 6rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.5rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.5rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.5rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.5rem !important;
  }
  .p-md-2 {
    padding: 1rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 1rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 1rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 1rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 1rem !important;
  }
  .p-md-3 {
    padding: 2rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 2rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 2rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 2rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 2rem !important;
  }
  .p-md-4 {
    padding: 3rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 3rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 3rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 3rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 3rem !important;
  }
  .p-md-5 {
    padding: 6rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 6rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 6rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 6rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 6rem !important;
  }
  .m-md-n1 {
    margin: -0.5rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.5rem !important;
  }
  .m-md-n2 {
    margin: -1rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -1rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -1rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -1rem !important;
  }
  .m-md-n3 {
    margin: -2rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -2rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -2rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -2rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -2rem !important;
  }
  .m-md-n4 {
    margin: -3rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -3rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -3rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -3rem !important;
  }
  .m-md-n5 {
    margin: -6rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -6rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -6rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -6rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -6rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.5rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.5rem !important;
  }
  .m-lg-2 {
    margin: 1rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 1rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 1rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 1rem !important;
  }
  .m-lg-3 {
    margin: 2rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 2rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 2rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 2rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 2rem !important;
  }
  .m-lg-4 {
    margin: 3rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 3rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 3rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 3rem !important;
  }
  .m-lg-5 {
    margin: 6rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 6rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 6rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 6rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 6rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.5rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.5rem !important;
  }
  .p-lg-2 {
    padding: 1rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 1rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 1rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 1rem !important;
  }
  .p-lg-3 {
    padding: 2rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 2rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 2rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 2rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 2rem !important;
  }
  .p-lg-4 {
    padding: 3rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 3rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 3rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 3rem !important;
  }
  .p-lg-5 {
    padding: 6rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 6rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 6rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 6rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 6rem !important;
  }
  .m-lg-n1 {
    margin: -0.5rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n2 {
    margin: -1rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -1rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -1rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -1rem !important;
  }
  .m-lg-n3 {
    margin: -2rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -2rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -2rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -2rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -2rem !important;
  }
  .m-lg-n4 {
    margin: -3rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -3rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -3rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -3rem !important;
  }
  .m-lg-n5 {
    margin: -6rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -6rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -6rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -6rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -6rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.5rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.5rem !important;
  }
  .m-xl-2 {
    margin: 1rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 1rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 1rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 1rem !important;
  }
  .m-xl-3 {
    margin: 2rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 2rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 2rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 2rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 2rem !important;
  }
  .m-xl-4 {
    margin: 3rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 3rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 3rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 3rem !important;
  }
  .m-xl-5 {
    margin: 6rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 6rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 6rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 6rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 6rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.5rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.5rem !important;
  }
  .p-xl-2 {
    padding: 1rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 1rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 1rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 1rem !important;
  }
  .p-xl-3 {
    padding: 2rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 2rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 2rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 2rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 2rem !important;
  }
  .p-xl-4 {
    padding: 3rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 3rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 3rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 3rem !important;
  }
  .p-xl-5 {
    padding: 6rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 6rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 6rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 6rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 6rem !important;
  }
  .m-xl-n1 {
    margin: -0.5rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n2 {
    margin: -1rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -1rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -1rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -1rem !important;
  }
  .m-xl-n3 {
    margin: -2rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -2rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -2rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -2rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -2rem !important;
  }
  .m-xl-n4 {
    margin: -3rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -3rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -3rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -3rem !important;
  }
  .m-xl-n5 {
    margin: -6rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -6rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -6rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -6rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -6rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #312a31 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #080708 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #8f1727 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #4d0c15 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
/*!
* MOKA Hotel - MOKA Mohnkaffeehaus https://moka.at
* Krzysztof Jeziorny 2019, <kj@acat.cc> Acat GmbH
*/
body { /* Addresses a small issue in webkit: http://bit.ly/NEdoDq */
  -webkit-backface-visibility: hidden;
}

.animated {
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
}

html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

.grid, .block-image_gallery_block ul, .site__content, .wrapper {
  flex-direction: column;
  place-content: flex-start flex-start;
  flex: 1;
  display: grid;
  grid-gap: 2rem;
  width: 100%;
  height: 100%;
}

.grid-reset, .page-title, .page__header,
.block--heading_block {
  grid-column: -1/1;
}

.wrapper {
  width: auto;
  grid-template-areas: "header" "main" "footer";
  grid-template-rows: auto 1fr auto;
  justify-content: center;
}

.site__header {
  flex-direction: column;
  grid-area: header;
  align-items: center;
}

.site__content {
  grid-area: main;
  margin: 0 auto;
  padding-left: 2rem;
  padding-right: 2rem;
}

@media (min-width: 576px) {
  .wrapper {
    margin: 2rem;
    max-width: 80rem;
  }
  .site__header {
    flex-direction: row;
  }
  .site__content .block--sub_block {
    grid-column: auto;
  }
  .site__content .page__content {
    flex-direction: column;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem 5rem;
  }
}
.site__footer {
  grid-area: footer;
  padding: 1rem 2rem 0 2rem;
  margin-left: 0;
  margin-right: 0;
}
@media (min-width: 576px) {
  .site__footer .copyright {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
  }
}

/* fira-sans-300normal - latin */
@font-face {
  font-family: "Fira Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: local("Fira Sans Light "), local("Fira Sans-Light"), url("../fonts/fira-sans/fira-sans-latin-300.woff2") format("woff2"), url("../fonts/fira-sans/fira-sans-latin-300.woff") format("woff"); /* Modern Browsers */
}
/* fira-sans-300italic - latin */
@font-face {
  font-family: "Fira Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src: local("Fira Sans Light italic"), local("Fira Sans-Lightitalic"), url("../fonts/fira-sans/fira-sans-latin-300italic.woff2") format("woff2"), url("../fonts/fira-sans/fira-sans-latin-300italic.woff") format("woff"); /* Modern Browsers */
}
/* fira-sans-400normal - latin */
@font-face {
  font-family: "Fira Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local("Fira Sans Regular "), local("Fira Sans-Regular"), url("../fonts/fira-sans/fira-sans-latin-400.woff2") format("woff2"), url("../fonts/fira-sans/fira-sans-latin-400.woff") format("woff"); /* Modern Browsers */
}
/* fira-sans-400italic - latin */
@font-face {
  font-family: "Fira Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: local("Fira Sans Regular italic"), local("Fira Sans-Regularitalic"), url("../fonts/fira-sans/fira-sans-latin-400italic.woff2") format("woff2"), url("../fonts/fira-sans/fira-sans-latin-400italic.woff") format("woff"); /* Modern Browsers */
}
/* fira-sans-700normal - latin */
@font-face {
  font-family: "Fira Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local("Fira Sans Bold "), local("Fira Sans-Bold"), url("../fonts/fira-sans/fira-sans-latin-700.woff2") format("woff2"), url("../fonts/fira-sans/fira-sans-latin-700.woff") format("woff"); /* Modern Browsers */
}
/* fira-sans-700italic - latin */
@font-face {
  font-family: "Fira Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: local("Fira Sans Bold italic"), local("Fira Sans-Bolditalic"), url("../fonts/fira-sans/fira-sans-latin-700italic.woff2") format("woff2"), url("../fonts/fira-sans/fira-sans-latin-700italic.woff") format("woff"); /* Modern Browsers */
}
body {
  font-family: "Fira Sans", -apple-system, blinkmacsystemfont, "Segoe UI", roboto, "Helvetica Neue", arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-feature-settings: "liga", "clig", "kern", "onum";
  font-variant-ligatures: common-ligatures;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  hyphens: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 1rem;
  font-weight: 500;
  line-height: 1.2;
  hyphens: none;
}

h1 {
  font-size: 42;
}

h2 {
  font-size: 32;
}

h3 {
  font-size: 28;
}

h4 {
  font-size: 22;
}

h5 {
  font-size: 18;
}

h1,
h2,
h3 {
  font-weight: 700;
}

dt,
dd {
  font-weight: 400;
}

em,
.ibm-type-italic {
  font-style: italic;
}

b,
strong {
  font-weight: 700;
}

.list-unstyled, .block-image_gallery_block ul, .block-image_block ul, .rich-text ul {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

body {
  background: #e9ecef;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23868588' fill-opacity='0.4' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
}

.wrapper {
  background: #fff;
  box-shadow: 0 0 0.8rem rgba(128, 128, 128, 0.3);
}

.site__header {
  background-color: #fff;
  color: #6c757d;
  padding: 1rem;
}

.site__name {
  display: block;
  overflow: hidden;
  margin: 0;
}
.site__name a {
  display: block;
  text-indent: -1000%;
  background: transparent url(../img/moka.svg) 50% 50% no-repeat;
  background-size: contain;
  height: 4rem;
  width: 10rem;
}
.site__name a:hover {
  text-decoration: none;
}

.navbar {
  padding: 0;
}

.navbar-nav {
  font-size: 1.4rem;
}
@media (min-width: 576px) {
  .navbar-nav {
    flex-direction: row;
  }
  .navbar-nav li {
    margin-right: 1rem;
    word-break: keep-all;
    hyphens: none;
  }
  .navbar-nav li.active .nav-link {
    font-weight: 600;
  }
}

.navbar-toggler {
  color: #edd5e3;
}

main p a {
  text-decoration: underline;
  hyphens: none;
}

img,
figure {
  max-width: 100%;
  height: auto;
}

.icon {
  display: inline-block;
  vertical-align: middle;
  width: 1rem;
  height: 1rem;
}
a .icon {
  fill: #8f1727;
}
.disabled a .icon {
  fill: #6c757d;
}

.rich-text ul {
  padding: 0;
  margin: 0;
}
.rich-text ul li {
  padding-left: 0.4rem;
}
.rich-text ul li::before {
  content: "•";
  padding-right: 0.4rem;
  color: #8f1727;
}
.rich-text p {
  text-align: justify;
}

figure {
  margin: 0;
}
figure figcaption {
  text-align: center;
  margin: 0.3rem auto;
  font-size: 90%;
  color: var(--dark);
  background-color: inherit;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  filter: drop-shadow(1px 1px 1px rgba(128, 128, 128, 0.7));
}

@media (min-width: 576px) {
  .block--column:nth-child(odd) .block-image_block {
    margin-left: -2rem;
  }
  .block--column:nth-child(odd) .block-image_block ul li:nth-child(2) figure {
    justify-content: flex-end;
    transform: translateX(5%);
  }
  .block--column:nth-child(even) .block-image_block {
    margin-right: -2rem;
    grid-column-start: 1;
  }
  .block--column:nth-child(even) .block-image_block ul li:nth-child(2) figure {
    left: 0;
    transform: translateX(-5%);
  }
}
.block--column:last-child {
  grid-column: -1/1;
}
.block-image_block {
  position: relative;
}
.block-image_block ul li {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.block-image_block ul li figure {
  display: flex;
}
.block-image_block ul li figure img {
  width: 100%;
  min-width: 100%;
  height: 100%;
}
.block-image_block ul li:nth-child(2) figure {
  position: absolute;
  z-index: 2;
  top: 25%;
}
.block-image_block ul li:nth-child(2) figure img {
  min-width: 50%;
  width: 50%;
  box-shadow: 0.2rem 0.2rem 0.4rem rgba(128, 128, 128, 0.6);
  border: 0.3rem solid #fff;
}
.block-image_gallery_block ul {
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
}
.block-image_gallery_block ul img {
  min-width: 100%;
}

.site__footer {
  background-color: #312a31;
  color: #fff;
}
.site__footer a {
  color: #fff;
}

.top {
  margin-right: 0.5rem;
}
.top svg {
  fill: #fff;
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  animation-name: fadeIn;
}

body.hidden-scroll {
  overflow: hidden;
}

.sl-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgb(233, 233, 233);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23868588' fill-opacity='0.4' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
  opacity: 0.85;
  display: none;
  z-index: 1035;
}

.sl-wrapper {
  z-index: 1040;
}
.sl-wrapper button {
  border: 0 none;
  background: transparent;
  font-size: 28px;
  padding: 0;
  cursor: pointer;
}
.sl-wrapper button:hover {
  opacity: 0.7;
}
.sl-wrapper .sl-close {
  display: none;
  position: fixed;
  right: 30px;
  top: 30px;
  z-index: 10060;
  margin-top: -14px;
  margin-right: -14px;
  height: 44px;
  width: 44px;
  line-height: 44px;
  font-family: inherit;
  color: #000;
  font-size: 3rem;
}
.sl-wrapper .sl-close:focus {
  outline: none;
}
.sl-wrapper .sl-counter {
  display: none;
  position: fixed;
  top: 30px;
  left: 30px;
  z-index: 1060;
  color: #000;
  font-size: 1rem;
}
.sl-wrapper .sl-navigation {
  width: 100%;
  display: none;
}
.sl-wrapper .sl-navigation button {
  position: fixed;
  top: 50%;
  margin-top: -22px;
  height: 44px;
  width: 22px;
  line-height: 44px;
  text-align: center;
  display: block;
  z-index: 10060;
  font-family: inherit;
  color: #000;
}
.sl-wrapper .sl-navigation button.sl-next {
  right: 5px;
  font-size: 2rem;
}
.sl-wrapper .sl-navigation button.sl-prev {
  left: 5px;
  font-size: 2rem;
}
.sl-wrapper .sl-navigation button:focus {
  outline: none;
}
@media (min-width: 35.5em) {
  .sl-wrapper .sl-navigation button {
    width: 44px;
  }
  .sl-wrapper .sl-navigation button.sl-next {
    right: 10px;
    font-size: 3rem;
  }
  .sl-wrapper .sl-navigation button.sl-prev {
    left: 10px;
    font-size: 3rem;
  }
}
@media (min-width: 50em) {
  .sl-wrapper .sl-navigation button {
    width: 44px;
  }
  .sl-wrapper .sl-navigation button.sl-next {
    right: 20px;
    font-size: 3rem;
  }
  .sl-wrapper .sl-navigation button.sl-prev {
    left: 20px;
    font-size: 3rem;
  }
}
.sl-wrapper .sl-image {
  position: fixed;
  touch-action: none;
  touch-action: none;
  z-index: 10000;
}
.sl-wrapper .sl-image img {
  margin: 0;
  padding: 0;
  display: block;
  border: 0 none;
  width: 100%;
  height: auto;
}
@media (min-width: 35.5em) {
  .sl-wrapper .sl-image img {
    border: 0 none;
  }
}
@media (min-width: 50em) {
  .sl-wrapper .sl-image img {
    border: 0 none;
  }
}
.sl-wrapper .sl-image iframe {
  background: #000;
  border: 0 none;
}
@media (min-width: 35.5em) {
  .sl-wrapper .sl-image iframe {
    border: 0 none;
  }
}
@media (min-width: 50em) {
  .sl-wrapper .sl-image iframe {
    border: 0 none;
  }
}
.sl-wrapper .sl-image .sl-caption {
  display: none;
  padding: 10px;
  color: #fff;
  background: rgba(0, 0, 0, 0.8);
  font-size: 1rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.sl-wrapper .sl-image .sl-caption.pos-top {
  bottom: auto;
  top: 0;
}
.sl-wrapper .sl-image .sl-caption.pos-outside {
  bottom: auto;
}
.sl-wrapper .sl-image .sl-download {
  display: none;
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: #000;
  z-index: 1060;
}

.sl-spinner {
  display: none;
  border: 5px solid #333;
  border-radius: 40px;
  height: 40px;
  left: 50%;
  margin: -20px 0 0 -20px;
  opacity: 0;
  position: fixed;
  top: 50%;
  width: 40px;
  z-index: 1007;
  animation: pulsate 1s ease-out infinite;
  animation: pulsate 1s ease-out infinite;
  animation: pulsate 1s ease-out infinite;
  animation: pulsate 1s ease-out infinite;
  animation: pulsate 1s ease-out infinite;
}

.sl-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.sl-transition {
  transition: -moz-transform ease 200ms;
  transition: -ms-transform ease 200ms;
  transition: -o-transform ease 200ms;
  transition: -webkit-transform ease 200ms;
  transition: transform ease 200ms;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
@keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
@keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
@keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
@keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
@media print {
  nav,
  .page__block:last-child,
  .block-image_gallery_block,
  .block-image_block {
    display: none;
  }
  .grid, .wrapper, .site__content, .block-image_gallery_block ul,
  .page__content {
    grid-template-columns: 1fr !important;
  }
  .page__block {
    grid-column: -1/1;
  }
}
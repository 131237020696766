@media print {
  nav,
  .page__block:last-child,
  .block-image_gallery_block,
  .block-image_block {
    display: none;
  }

  .grid,
  .page__content {
    grid-template-columns: 1fr !important;
  }

  .page__block {
    grid-column: -1/1;
  }
}

// MOKA
$primary: #d70d19;
$spacer: 2rem;

:root {
  --primary: #312a31;
  --red: #8f1727;
}

$font-family-sans: "Fira Sans", -apple-system, blinkmacsystemfont, "Segoe UI", roboto, "Helvetica Neue", arial, sans-serif, "Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
$font-family-serif: "Georgia", times, serif;
$enable-flex: true;
$enable-shadows: true;

// $input-btn-line-height: 1.35;
$carousel-control-icon-width: 2rem;

// palette 1
$long-lake: #68747b;
$two-harbors: #bdd3e1;
$iris-ice: #dee0ef;
$sonora-rose: #edd5e3;
$lovers-retreat: #f4ceda;
$sunset-dream: rgb(255 226 212);
$go-to-gray: #f1eaec;
$primary: #312a31;
$red: #8f1727;
$background-color: $go-to-gray;
$text-color: $primary;
$link-color: $red;
$link-hover-color: darken($red, 10%);
$modal-content-bg: $go-to-gray;
$breadcrumb-bg: transparent;
$angle-size: 10%;
$table-cell-padding: 0.4rem;
$table-hover-bg: rgba(#fff, 0.5);
$popover-max-width: 60rem;
$modal-lg: 70rem;
$navbar-dark-toggler-border-color: transparent;

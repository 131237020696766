$font-prefix: "../fonts";

/* fira-sans-300normal - latin */
@font-face {
  font-family: "Fira Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local("Fira Sans Light "),
    local("Fira Sans-Light"),
    url("../fonts/fira-sans/fira-sans-latin-300.woff2") format("woff2"),
    url("../fonts/fira-sans/fira-sans-latin-300.woff") format("woff"); /* Modern Browsers */
}

/* fira-sans-300italic - latin */
@font-face {
  font-family: "Fira Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local("Fira Sans Light italic"),
    local("Fira Sans-Lightitalic"),
    url("../fonts/fira-sans/fira-sans-latin-300italic.woff2") format("woff2"),
    url("../fonts/fira-sans/fira-sans-latin-300italic.woff") format("woff"); /* Modern Browsers */
}

/* fira-sans-400normal - latin */
@font-face {
  font-family: "Fira Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local("Fira Sans Regular "),
    local("Fira Sans-Regular"),
    url("../fonts/fira-sans/fira-sans-latin-400.woff2") format("woff2"),
    url("../fonts/fira-sans/fira-sans-latin-400.woff") format("woff"); /* Modern Browsers */
}

/* fira-sans-400italic - latin */
@font-face {
  font-family: "Fira Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local("Fira Sans Regular italic"),
    local("Fira Sans-Regularitalic"),
    url("../fonts/fira-sans/fira-sans-latin-400italic.woff2") format("woff2"),
    url("../fonts/fira-sans/fira-sans-latin-400italic.woff") format("woff"); /* Modern Browsers */
}

/* fira-sans-700normal - latin */
@font-face {
  font-family: "Fira Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local("Fira Sans Bold "),
    local("Fira Sans-Bold"),
    url("../fonts/fira-sans/fira-sans-latin-700.woff2") format("woff2"),
    url("../fonts/fira-sans/fira-sans-latin-700.woff") format("woff"); /* Modern Browsers */
}

/* fira-sans-700italic - latin */
@font-face {
  font-family: "Fira Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local("Fira Sans Bold italic"),
    local("Fira Sans-Bolditalic"),
    url("../fonts/fira-sans/fira-sans-latin-700italic.woff2") format("woff2"),
    url("../fonts/fira-sans/fira-sans-latin-700italic.woff") format("woff"); /* Modern Browsers */
}

$font-family-sans: "Fira Sans", -apple-system, blinkmacsystemfont, "Segoe UI", roboto, "Helvetica Neue", arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-size-base: $rfs-base-font-size;

body {
  font-family: $font-family-sans;
  font-feature-settings: "liga", "clig", "kern", "onum";
  font-variant-ligatures: common-ligatures;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  hyphens: auto;
}

// headings
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: $headings-margin-bottom;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
  hyphens: none;
}

h1 {
  // @include responsive-font-size(3rem);
  @include rfs(42);
}

h2 {
  // @include responsive-font-size(2.5rem);
  @include rfs(32);
}

h3 {
  // @include responsive-font-size(2rem);
  @include rfs(28);
}

h4 {
  // @include responsive-font-size(1.5rem);
  @include rfs(22);
}

h5 {
  @include rfs(18);
}

h1,
h2,
h3 {
  font-weight: $font-weight-bold;
}

dt,
dd {
  font-weight: $font-weight-normal;
}

em,
.ibm-type-italic {
  font-style: italic;
}

b,
strong {
  font-weight: $font-weight-bold;
}

//
// Lists
//

.list-unstyled {
  @include list-unstyled;
}

// Inline turns list items into inline-block
.list-inline {
  @include list-unstyled;
}

.list-inline-item {
  display: inline-block;

  &:not(:last-child) {
    margin-right: $list-inline-padding;
  }
}

html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

.grid {
  flex-direction: column;
  place-content: flex-start flex-start;
  flex: 1;
  display: grid;
  grid-gap: $spacer;
  width: 100%;
  height: 100%;
}

.grid-reset {
  grid-column: -1/1;
}

.wrapper {
  @extend .grid;

  width: auto;
  grid-template-areas:
    "header"
    "main"
    "footer";
  grid-template-rows: auto 1fr auto;
  justify-content: center;
}

.site__header {
  flex-direction: column;
  grid-area: header;
  align-items: center;
}

.site__content {
  @extend .grid;

  grid-area: main;
  margin: 0 auto;
  padding-left: $spacer;
  padding-right: $spacer;
}

.page__header,
.block--heading_block {
  @extend .grid-reset;
}

// larger
@include respond-above(sm) {
  .wrapper {
    margin: $spacer;
    max-width: 80rem;
  }

  .site__header {
    flex-direction: row;
  }

  .site__content {
    .block--sub_block {
      grid-column: auto;
    }

    .page__content {
      flex-direction: column;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: $spacer + 1rem $spacer + 3rem;
    }
  }
}

// Footer
.site__footer {
  grid-area: footer;
  padding: 1rem $spacer 0 $spacer;
  margin-left: 0;
  margin-right: 0;

  @include respond-above(sm) {
    .copyright {
      display: grid;
      grid-gap: $spacer;
      grid-template-columns: repeat(3, 1fr);
      align-items: center;
    }
  }
}

.page-title {
  @extend .grid-reset;
}
